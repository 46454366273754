import {
  CatalogSearch,
  ICatalogHistoryItem,
  ICatalogSearchRequest,
  ICatalogSearchResponse,
  ICategory,
  IGetDeliveryBranchRequest,
  IGetDeliveryBranchResponse,
  IGetProductVariationByAttributeValueRequest,
  IProduct,
  ISendOrderRequest,
  ISendOrderResponse,
  ProductAttributeForCheck,
} from '../types';

import * as API from 'src/api';

const API_ENDPOINT_ORDER = 'place-order';
const API_ENDPOINT_HISTORY = 'user-orders';
const API_ENDPOINT_CATEGORIES = 'get-categories';
const API_ENDPOINT_PRODUCTS = 'get-products';
const API_ENDPOINT_PRODUCT = 'product';
const API_ENDPOINT_PRODUCTS_BY_ID = 'get-products-by-ids';
const API_ENDPOINT_DELIVERY_BRANCH = 'delivery-branch';
const API_ENDPOINT_SEARCH = 'catalog-search';

export interface ISuggestion {
  data: {
    geo_lat: string;
    geo_lon: string;
    [key: string]: Nullable<string>;
  };
  unrestricted_value: string;
  value: string;
}

export async function sendOrder(data: ISendOrderRequest): Promise<ISendOrderResponse> {
  return API.request<ISendOrderResponse>({
    data,
    method: 'post',
    url: API_ENDPOINT_ORDER,
  });
}

export interface ILoadHistoryRequest {
  limit?: number;
  page?: number;
}

export interface ILoadHistoryResponse {
  total: number;
  items: ICatalogHistoryItem[];
}

export const loadHistory = (
  data?: ILoadHistoryRequest,
): Promise<ILoadHistoryResponse> => {
  return API.request<ILoadHistoryResponse>({
    method: 'post',
    url: API_ENDPOINT_HISTORY,
    data,
  });
};

export interface IGetCategoriesResponse {
  category: ICategory[];
}

export interface IGetCategoriesRequest {
  branch?: Nullable<number | string>;
}

export const getCategories = (data: IGetCategoriesRequest): Promise<ICategory[]> =>
  API.request<IGetCategoriesResponse>({
    method: 'post',
    url: API_ENDPOINT_CATEGORIES,
    data,
  }).then(({ category }) => category);

export interface IGetProductsRequest {
  limit: number;
  offset: number;
  category?: number;
  branch?: Nullable<number | string>;
}

export const getProducts = (data: IGetProductsRequest): Promise<IProduct[]> =>
  API.request<IProduct[]>({
    method: 'post',
    url: API_ENDPOINT_PRODUCTS,
    data,
  });

export interface IGetProductsByIdsRequest {
  products: number[];
  variations?: { id: number; productAttributeValues: ProductAttributeForCheck[] }[];
  branch?: Nullable<number | string>;
}

export const getProductsByIds = (data: IGetProductsByIdsRequest): Promise<IProduct[]> =>
  API.request<IProduct[]>({
    method: 'post',
    url: API_ENDPOINT_PRODUCTS_BY_ID,
    data,
  });

export const getDeliveryBranch = (
  params: IGetDeliveryBranchRequest,
): Promise<{ idBranch: number }> =>
  API.request<IGetDeliveryBranchResponse>({
    method: 'get',
    url: API_ENDPOINT_DELIVERY_BRANCH,
    params,
  }).then((response) => response.data);

export const catalogSearch = (params: ICatalogSearchRequest): Promise<CatalogSearch> =>
  API.request<ICatalogSearchResponse>({
    method: 'get',
    url: API_ENDPOINT_SEARCH,
    params,
  }).then((response) => response.catalogSearch);

export const getProductVariationByAttributeValue = async (
  params: IGetProductVariationByAttributeValueRequest,
): Promise<IProduct> => {
  const productId = params.id;

  return API.request<IProduct>({
    method: 'get',
    url: API_ENDPOINT_PRODUCT + '/' + productId + '/variation',
    params,
  });
};
