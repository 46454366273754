import { AppData, IDomainTokens, IMultipleSelect, DebugControl } from '@/types';
import { ISendStatRequest } from '@/misc';
import { ITabsState } from '@/store/appstore';

import transformAppDataSettings from '@/misc/transformAppDataSettings';

export default class AppState {
  authToken = '';
  idDevice = '';
  uuid = '';

  currentTab: string | null = null;

  regDeviceDidFail = false;

  appData: AppData = transformAppDataSettings({});
  appDataLoading = false;
  appDataDidLoad = false;
  appDataDidFail = false;
  appDataPromise: Promise<any> | null = null;

  multipleSelect: IMultipleSelect | null = null;

  swipeBack = true;
  swipePanel = true;

  panelOpened = false;

  online = true;

  secondRun = false;
  splashVisible = true;

  tabDidOpen = {} as ITabsState;

  domain: relatedDomain = '';
  domainTokens: IDomainTokens = {};
  domainFirstSelect = false;

  lazyLoadedImagesUrls: string[] = [];
  lazyLoadedImagesData: Record<string, Nullable<Blob>> = {};

  statQueue: ISendStatRequest[] = [];
  statInQueue: Nullable<ISendStatRequest> = null;

  debugControl: DebugControl = {
    active: false,
    cardView: false,
    gmbServer: false,
  };
  verbose: boolean = false;

  currentCoords: Coords = { lat: 0, lng: 0 };

  debugPWA: boolean = false;

  locale: string = '';
  host: 'client' | 'gmb' | 'stage' | string = 'client';

  pushToken: Nullable<string> = null;
}
