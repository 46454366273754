import { render, staticRenderFns } from "./product-push.vue?vue&type=template&id=6b49b478&"
import script from "./product-push.vue?vue&type=script&lang=ts&"
export * from "./product-push.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../../node_modules/.pnpm/vue-loader@15.9.8_wzvh6gi4tchptuexh7cjhim74e/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports