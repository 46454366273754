import Vue from 'vue';
import { Actions, Context } from 'vuex-smart-module';
import { Store } from 'vuex';

import { IBranch } from '@/types';
import {
  AddressDetails,
  DeliveryAddress,
  ICategory,
  IProduct,
  ISendOrderRequest,
  ISendOrderResponse,
  ProductAttributeForCheck,
  ReceiveType,
} from '../types';
import { IGetProductsRequest } from '../api';

import * as API from '../api';
import { openUrl, rgbToHex, showErrorDialog } from '@/misc';

let getCategoriesPromise: Nullable<Promise<ICategory[]>> = null;

import AppStore from '@/store/appstore';
import CatalogGetters from './getters';
import CatalogMutations from './mutations';
import CatalogState from './state';

export default class CatalogActions extends Actions<
  CatalogState,
  CatalogGetters,
  CatalogMutations,
  CatalogActions
> {
  app!: Context<typeof AppStore>;

  $init(store: Store<typeof AppStore>): void {
    this.app = AppStore.context(store);
  }

  addToBasket({ amount, product }: { amount: number; product: IProduct }) {
    const productAlreadyInBasket = this.state.basket.find(
      (entry) => entry.product.id === product.id,
    );

    if (productAlreadyInBasket) {
      this.commit('updateProduct', { amount, product });
    } else {
      this.commit('addProduct', { amount, product });
    }
  }

  clearBasket() {
    this.commit('setBasket', []);
  }

  sendRequest(data: ISendOrderRequest): Promise<ISendOrderResponse> {
    return API.sendOrder(data);
  }

  getCategories({ flush }: { flush?: boolean } = {}): Promise<ICategory[]> {
    if (getCategoriesPromise) {
      return getCategoriesPromise;
    }

    // if (flush && this.state.categories.length) {
    //   this.commit('setCategories', []);
    // }

    return flush || !this.state.categories.length
      ? (getCategoriesPromise = API.getCategories({
          branch: this.getters.receiveBranchId || undefined,
        })
          .then((categories) => {
            this.commit('setCategories', categories);

            return categories;
          })
          .finally(() => {
            getCategoriesPromise = null;
          }))
      : Promise.resolve(this.state.categories);
  }

  async updateBasket(): Promise<boolean> {
    const productsIds = this.state.basket
      .filter((item) => !item.product.isVariation)
      .map((item) => item.product.id);
    const variations = this.state.basket.filter(
      (item) => item.product.isVariation && item.product.id,
    );

    let updated = false;

    if (productsIds.length || variations.length) {
      const products = await API.getProductsByIds({
        branch: this.getters.receiveBranchId,
        products: productsIds,
        variations: variations.reduce((acc, cur) => {
          acc.push({
            id: cur.product.id,
            productAttributeValues: cur.product.productAttributeValues
              .map((attribute) => ({
                attributeId: attribute.id,
                attributeName: attribute.name,
                valueId: attribute.values.find((v) => v.selected)?.id || 0,
                valueName: attribute.values.find((v) => v.selected)?.name || '',
              }))
              .filter((a) => a.valueId !== 0 && a.valueName !== ''),
          });
          return acc;
        }, [] as { id: number; productAttributeValues: ProductAttributeForCheck[] }[]),
      });

      const allIds = productsIds.concat(variations.map((v) => v.product.id));

      allIds.forEach((id) => {
        const product = products.find((product) => product.id == id);
        const oldProduct = this.state.basket.find((item) => item.product.id == id)!;

        if (product) {
          if (!updated && oldProduct.product.price !== product.price) {
            updated = true;
          }

          // специально не обновляем атрибуты, чтобы показать, что именно старого варианта нет в продаже
          const updatedProduct: IProduct = JSON.parse(JSON.stringify(product));
          updatedProduct.productAttributeValues = JSON.parse(
            JSON.stringify(oldProduct.product.productAttributeValues),
          );

          this.commit('updateProduct', { amount: 0, product: updatedProduct });
        } else {
          updated = true;

          this.commit('updateProduct', {
            amount: 0,
            product: { ...oldProduct.product, ...{ deleted: true } },
          });
        }
      });
    }

    return updated;
  }

  getProductsByCategoryId({
    reset,
    ...data
  }: IGetProductsRequest & { reset?: boolean }): Promise<IProduct[]> {
    if (data.category && !data.offset && !reset) {
      const cachedCategoryProducts = this.state.productsByCategoryId[data.category];

      if (cachedCategoryProducts) {
        return Promise.resolve(cachedCategoryProducts.slice(0, data.limit));
      }
    }

    data.branch = this.getters.receiveBranchId;

    return API.getProducts(data).then((products) => {
      if (data.category) {
        this.commit('setProductsByCategoryId', {
          categoryId: data.category,
          products: products,
          reset,
        });
      }

      return products;
    });
  }

  checkout(
    params: {
      receive?: ReceiveType;
      address: string;
      comment?: string;
      name?: string;
      phone: string;
      paymentTypeId: number;
      toBranch?: string;
      email?: string;
      pickupTime?: string;
    },
    component: {
      instance: Vue;
      inAppBrowserLoadStartHandler: Function;
      inAppBrowserExitHandler: Function;
      afterCheckoutCallback: () => void;
    },
  ) {
    const room = this.state.deliveryAddressDetails?.room || '';
    const domofon = this.state.deliveryAddressDetails?.domofon || '';
    const entrance = this.state.deliveryAddressDetails?.entrance || '';
    const floor = this.state.deliveryAddressDetails?.floor || '';
    const lat = this.state.deliveryAddress?.data.lat;
    const lng = this.state.deliveryAddress?.data.lng;
    const addressComment = this.state.deliveryAddressDetails?.comment;
    const comment = params.comment
      ? params.comment + (addressComment ? '\n\n' + addressComment : '')
      : addressComment;

    const requestParams: ISendOrderRequest = {
      // @ts-ignore
      order: {
        address: params.address,
        comment,
        name: params.name || '',
        phone: params.phone,
        products: this.state.basket
          .filter((entry) => entry.product.active)
          .map((entry) => ({
            product_id: entry.product.id,
            count: entry.amount,
            paidByBonus: this.state.paidByBonus
              .filter((product) => product.id === entry.product.id)
              .reduce((acc, current) => {
                acc += current.price;
                return acc;
              }, 0),
          })),
        receiveType: params.receive,
        toBranch: params.toBranch,
        room,
        domofon,
        entrance,
        floor,
        lat,
        lng,
        paymentType: params.paymentTypeId,
        email: params.email,
      },
    };

    if (params.pickupTime) {
      requestParams.order.comment +=
        (requestParams.order.comment ? '\n\n' : '') + 'Самовывоз в ' + params.pickupTime;
    }

    component.instance.$f7?.preloader.show();

    this.sendRequest(requestParams)
      .then(({ additionalAction }) => {
        console.log(additionalAction);

        if (additionalAction) {
          const appGlobalMainColorParts = getComputedStyle(document.documentElement)
            .getPropertyValue('--app-global-main-color')
            .split(',') as [string, string, string];
          const appGlobalMainFgColorParts = getComputedStyle(document.documentElement)
            .getPropertyValue('--app-global-main-fg-color')
            .split(',') as [string, string, string];
          const appGlobalMainColorHex = rgbToHex.apply(this, appGlobalMainColorParts);
          const appGlobalMainFgColorHex = rgbToHex.apply(this, appGlobalMainFgColorParts);

          const options: any = component.instance.$theme.ios
            ? {
                toolbarcolor: appGlobalMainColorHex,
                closebuttoncolor: appGlobalMainFgColorHex,
                hardwareback: 'no',
                hideurlbar: 'yes',
                hidenavigationbuttons: 'yes',
              }
            : {
                footercolor: appGlobalMainColorHex,
                hideurlbar: 'yes',
                hidenavigationbuttons: 'yes',
                toolbarcolor: appGlobalMainColorHex,
                usewkwebview: 'yes',
                enableViewportScale: 'no',
                zoom: 'no',
                closebuttoncolor: appGlobalMainFgColorHex,
                toolbarposition: 'top',
              };

          const InAppBrowserInstance = openUrl(
            additionalAction.params.url,
            '_blank',
            undefined,
            Object.entries(options)
              .reduce((acc, current) => {
                acc.push(current.join('='));
                return acc;
              }, [] as string[])
              .join(','),
          );

          InAppBrowserInstance.addEventListener('loadstart', (e: any) => {
            component.inAppBrowserLoadStartHandler(
              e,
              additionalAction,
              InAppBrowserInstance,
            );
          });

          InAppBrowserInstance.addEventListener('exit', (e: any) => {
            component.inAppBrowserExitHandler(e, additionalAction, InAppBrowserInstance);
          });
        } else {
          const msgTitle =
            this.getters.settings.finishMessageTitle ??
            component.instance.$t('modules.catalog.form.result.title').toString();
          const msgText =
            this.getters.settings.finishMessageText ??
            component.instance.$t('modules.catalog.form.result.text').toString();

          if (msgTitle || msgText) {
            component.instance.$f7?.dialog.alert(
              msgText,
              msgTitle,
              component.afterCheckoutCallback,
            );
          } else {
            component.afterCheckoutCallback();
          }
        }
      })
      .catch((error) => showErrorDialog.call(component.instance, { error }))
      .finally(() => {
        component.instance.$f7?.preloader.hide();
      });
  }

  setPickupPoint(branch: Nullable<IBranch>) {
    this.commit('setPickupPoint', { branch, domain: this.app.state.domain });
  }

  setDeliveryAddress({
    suggestion,
    details,
    branch,
  }: {
    suggestion: Nullable<DeliveryAddress>;
    details: Nullable<AddressDetails>;
    branch: Nullable<number | string>;
  }) {
    this.commit('setDeliveryAddress', {
      suggestion,
      details,
      branch,
      domain: this.app.state.domain,
    });
  }

  removeNonActiveProductsFromBasket() {
    this.commit(
      'setBasket',
      this.state.basket.filter((entry) => entry.product.active !== false),
    );
  }

  removeNotForSaleProductsFromBasket() {
    this.commit(
      'setBasket',
      this.state.basket.filter((entry) => this.getters.getAllowBasket(entry.product)),
    );
  }
}
