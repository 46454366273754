


























































import { defineComponent, PropType } from 'vue';
import StarRating from 'vue-star-rating';
import { f7 } from 'framework7-vue';

import { IFeedback, ISendFeedbackRequest } from '../types';

import { showErrorDialog } from 'src/misc';
import { appStoreMapper } from 'src/store/appstore';
import { feedbackStoreMapper } from '../store';
import useValidatationState from 'src/validators/validation-state';
import bus from '@/bus';

import ListInputTextarea from 'src/components/fields/list-input-textarea.vue';
import ListInputSelect from '@/components/fields/list-input-select.vue';

export default defineComponent({
  name: 'FeedbackForm',
  components: { ListInputSelect, ListInputTextarea, StarRating },
  props: {
    sheet: Boolean as PropType<any>,
    entityId: Number as PropType<number>,
  },
  data: () => ({
    buttonClicked: false,
    rating: 0,
    content: '',
    loading: false,
    savedFeedback: null as Nullable<IFeedback>,
    branchId: null as Nullable<number>,
  }),
  setup() {
    const { validationState, onStateChanged, touchAll } = useValidatationState();
    return { validationState, onStateChanged, touchAll };
  },
  mounted() {
    if (this.branches.length === 1) {
      this.branchId = this.branches[0].idBranch;
    } else if (this.user?.lastVisitedBranchId) {
      const branchExists = this.branches.find(
        (b) => b.idBranch === this.user?.lastVisitedBranchId,
      );
      if (branchExists) {
        this.branchId = branchExists.idBranch;
      }
    }
  },
  computed: {
    branchesOptions(): ListInputOption[] {
      return this.branches.map((branch) => ({
        name: branch.name,
        value: branch.idBranch,
      }));
    },
    buttonDisabled(): boolean {
      return !this.rating;
    },
    showExternalFeedback(): boolean {
      const branch = this.branches.find((p) => p.idBranch === this.branchId);
      if (!branch) return false;

      const anyButtonIsActive =
        !!branch.googleFeedbackSharingURL ||
        !!branch.twoGISFeedbackSharingURL ||
        !!branch.yandexFeedbackSharingURL;

      return (
        anyButtonIsActive &&
        this.settings.enableCollectingNPSFeedback &&
        this.settings.enableThirdPartyFeedbackImprovement &&
        this.rating * 2 >= this.settings.feedbackShareThreshold
      );
    },
    ...appStoreMapper.mapGetters(['defaultTab', 'branches', 'user']),
    ...feedbackStoreMapper.mapGetters(['settings']),
  },
  methods: {
    doSendFeedback() {
      let params: ISendFeedbackRequest = {
        idBranch: this.branchId || 0,
      };

      if (this.content) {
        params.content = this.content;
      }

      if (this.rating) {
        params.quality = this.rating * 2;
      }

      if (this.entityId) {
        params.bonus = this.entityId;
      }

      f7.preloader.show();
      this.loading = true;

      this.sendFeedback(params)
        .then((response) => {
          if (!this.showExternalFeedback) {
            f7.dialog.alert(
              this.$t('modules.feedback.form.result.text') as string,
              this.$t('modules.feedback.form.result.title') as string,
              () => {
                this.resetState();
                this.goBack();
              },
            );
          } else {
            this.savedFeedback = response as IFeedback;
          }
        })
        .catch((error) => showErrorDialog.call(this, { error }))
        .finally(() => {
          f7.preloader.hide();
          this.loading = false;

          if (this.sheet) {
            bus.$emit('feedback:sheet:hide');
          } else {
            this.goBack();
          }

          if (this.showExternalFeedback) {
            setTimeout(() => {
              bus.$emit('feedback:external:show', this.savedFeedback);
            }, 300);
          }

          this.resetState();
        });
    },
    goBack() {
      f7.views.current.router.back();
    },
    onCancelClick() {
      f7.sheet.close();
    },
    onSendClick() {
      this.buttonClicked = true;

      this.$nextTick(() => {
        if (this.validationState.invalid) {
          this.touchAll();

          const invalidInput = this.$el.querySelector(
            '.item-input-invalid input, .item-input-invalid textarea',
          ) as HTMLInputElement;

          if (invalidInput) {
            invalidInput.focus();
          }

          return;
        }

        this.doSendFeedback();
      });
    },
    resetState() {
      this.content = '';
      this.rating = 0;
      this.buttonClicked = false;
    },
    ...feedbackStoreMapper.mapActions(['sendFeedback']),
  },
});
