













import Vue from 'vue';
import { defineComponent, PropType } from 'vue';

import { required } from 'src/validators/validators';
import i18n from 'src/translate/lang';

import ListInput from 'src/components/fields/list-input.vue';

export default defineComponent({
  name: 'ListInputSelect',
  components: { ListInput },
  props: {
    value: [String, Number] as PropType<string | number>,
    required: {
      type: Boolean as PropType<boolean>,
      default: () => false,
    },
    showInfo: {
      type: Boolean as PropType<boolean>,
      default: () => false,
    },
    showError: Boolean as PropType<boolean>,
    label: {
      type: String as PropType<string>,
      default: () => i18n.t('global.fields.time.label'),
    },
    options: Array as PropType<ListInputOption[]>,
    placeholder: String as PropType<String>,
  },
  data: () => ({
    validationParams: {
      $autoDirty: true,
    } as any,
  }),
  watch: {
    required: {
      handler(value: boolean) {
        if (value) {
          this.validationParams.required = required;
        } else {
          Vue.delete(this.validationParams, 'required');
        }
      },
      immediate: true,
    },
  },
});
