





































































import Vue from 'vue';

import config from '@/app.config.js';
import { appStoreMapper } from '@/store/appstore';
import * as API from '@/api';
import bus from '@/bus';
import { formatDate } from '@/utils';

export default Vue.extend({
  name: 'DebugControl',
  data: () => ({
    innerHost: '',
  }),
  watch: {
    host: {
      handler(value: string) {
        this.innerHost = value;
      },
      immediate: true,
    },
  },
  computed: {
    show(): boolean {
      return window.devMode;
    },
    cardToggledByDefault(): boolean {
      return this.appData.settings.modules.bonus?.homeBonusButtonType === 'card';
    },
    ...appStoreMapper.mapState(['debugControl', 'appData', 'locale', 'host']),
  },
  methods: {
    formatDate,
    onClick() {
      this.setDebugControl({ ...this.debugControl, active: !this.debugControl.active });
    },
    onInputClickPrevent(e: Event) {
      e.stopPropagation();
    },
    onHostConfirm(e: InputEvent) {
      this.onSelectHost((e.target as HTMLInputElement).value);
    },
    toggleCardView() {
      this.setDebugControl({
        ...this.debugControl,
        cardView: !this.debugControl.cardView,
      });

      const cardView = this.debugControl.cardView;
      const appData = JSON.parse(JSON.stringify(this.appData));

      appData.settings.modules.app.smallLogo = cardView;
      appData.settings.modules.app.hideLogo = cardView;

      if (appData.settings.modules.bonus) {
        appData.settings.modules.bonus.homeBonusButtonType = cardView ? 'card' : 'button';
      }

      this.setAppData(appData);
    },
    onSelectHost(value: 'client' | 'gmb' | 'stage' | string) {
      let domain = '';

      if (!value) {
        domain = 'client';
      }

      switch (value) {
        case 'client':
          domain = config.api.client;
          break;
        case 'stage':
          domain = 'demo';
          break;
        case 'gmb':
        default:
          domain = value;
      }

      this.setHost(value);
      this.setDomain(domain);

      API.setBaseUrl(domain, this.host);
      this.getAppData(bus).finally(() => {
        bus.$emit('home-ptr');
      });
    },
    onLocaleClick(value: string) {
      this.changeLocale(value);
    },
    ...appStoreMapper.mapMutations([
      'setDebugControl',
      'setAppData',
      'setHost',
      'setDomain',
    ]),
    ...appStoreMapper.mapActions(['getAppData', 'changeLocale']),
  },
});
