







































































































































import Vue from 'vue';

import { ReceiveType } from '../types';

import { formatNumber, showErrorDialog } from '@/misc';
import { appStoreMapper } from 'src/store/appstore';
import { catalogStoreMapper } from '../store';
import { getLocalizedPrice } from '@/utils';

import BasketEntry from './basket-entry.vue';
import CatalogBarWrapper from './CatalogBarWrapper.vue';
import Time from './time.vue';

export default Vue.extend({
  components: { BasketEntry, CatalogBarWrapper, Time },
  data: () => ({
    formatNumber,
    ReceiveType,
    loading: false,
  }),
  computed: {
    showDeliveryMinimumPrice(): boolean {
      return (
        (!!this.settings.delivery && !!this.settings.delivery.minOrder) ||
        (!!this.settings.pickup &&
          !!this.settings.pickup.minOrder &&
          this.totalProductsPrice < this.minOrder)
      );
    },
    ...appStoreMapper.mapGetters([
      'decimalOptions',
      'miniappHideElements',
      'pickupPoints',
      'user',
      'currency',
    ]),
    ...catalogStoreMapper.mapGetters([
      'minOrder',
      'notWorkingTime',
      'settings',
      'totalProductsPrice',
      'totalPriceDelivery',
      'totalPricePickup',
      'settings',
      'workingTimeLabel',
      'notActiveProductsList',
      'notForSaleProductsList',
    ]),
    ...catalogStoreMapper.mapState(['basket', 'pickupPoint', 'receive', 'inBasket']),
  },
  methods: {
    reset() {
      this.$f7?.dialog.confirm(
        this.$t('modules.catalog.dialogs.clear.text') as string,
        this.$t('modules.catalog.dialogs.clear.title') as string,
        () => {
          this.clearBasket();
        },
      );
    },
    onPageBeforeIn() {
      this.setInBasket(true);
      this.setPayByBonusOrderAmount(0);
    },
    onPageBeforeOut() {
      this.setInBasket(false);
    },
    localizePrice(value: number): string {
      return getLocalizedPrice(
        value,
        this.currency,
        this.decimalOptions.decimalRoundingRule,
        this.decimalOptions.decimal,
        {
          useMils: true,
        },
      );
    },
    ...catalogStoreMapper.mapActions(['clearBasket', 'updateBasket']),
    ...catalogStoreMapper.mapMutations(['setInBasket', 'setPayByBonusOrderAmount']),
  },
  watch: {
    inBasket(value: boolean) {
      if (value) {
        this.loading = true;
        this.$f7.preloader.show();

        this.updateBasket()
          .catch((e) => {
            showErrorDialog.call(this, e);
          })
          .finally(() => {
            this.loading = false;
            this.$f7.preloader.hide();
          });
      }
    },
  },
});
