




import Vue from 'vue';

import { bonusStoreMapper } from '../store';

import BonusProgress from './BonusProgress.vue';

export default Vue.extend({
  name: 'BonusHomeSlotGroup',
  components: { BonusProgress },
  computed: {
    show(): boolean {
      return this.settings.groups.length > 1;
    },
    ...bonusStoreMapper.mapGetters(['settings']),
  },
});
