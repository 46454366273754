import { Context, Getters } from 'vuex-smart-module';
import { Store } from 'vuex';

import { IBranch } from '@/types';
import {
  IBasketEntry,
  ICategory,
  IconType,
  IModuleSettings,
  IProduct,
  ReceiveType,
} from '../types';

import { timeZone, checkWorkingTime } from '@/utils';
import i18n from '@/translate/lang';

import AppStore from '@/store/appstore';
import CatalogState from './state';

export default class CatalogGetters extends Getters<CatalogState> {
  app!: Context<typeof AppStore>;

  $init(store: Store<typeof AppStore>): void {
    this.app = AppStore.context(store);
  }

  get totalProducts() {
    let amount = 0;

    this.state.basket
      .filter((entry) => !entry.product.deleted && (entry.product.active ?? true))
      .forEach((entry) => {
        amount += entry.amount;
      });

    return amount;
  }

  get basketWithActualItems() {
    return this.state.basket.filter(
      (entry) => !entry.product.deleted && (entry.product.active ?? true),
    );
  }

  get totalProductsPrice() {
    let price = 0;

    // this.getters.basketWithActualItems.forEach((entry) => {
    //   const paidByBonus = this.state.paidByBonus.filter((e) => e.id === entry.product.id);
    //   price +=
    //     entry.product.price * entry.amount -
    //     Math.floor(
    //       (entry.product.price * paidByBonus.length * entry.product.maxPayBonus) / 100,
    //     );
    // });

    this.getters.basketWithActualItems.forEach((entry) => {
      price += entry.product.price * entry.amount;
    });

    return price;
  }

  get totalPayedByBonus() {
    let amount = 0;

    this.state.paidByBonus.forEach((item) => {
      amount += Math.floor(item.price * ((100 - this.getters.discount) / 100));
    });

    return amount;
  }

  get totalAmountOfBonusAvailableForPayByBonus() {
    let summ = 0;

    this.getters.basketWithActualItems.forEach((item) => {
      if (this.getters.settings.payByBonusType === 'order') {
        summ +=
          item.amount *
          Math.floor(
            item.product.price *
              ((100 - this.getters.discount) / 100) *
              (Math.min(item.product.maxPayBonus, this.getters.settings.maxPayBonusK) /
                100),
          );
      } else {
        summ +=
          item.amount *
          Math.floor(item.product.price * ((100 - this.getters.discount) / 100));
      }
    });

    return summ;
  }

  get totalPricePickup() {
    let price = this.getters.totalProductsPrice;

    if (
      this.state.receive === ReceiveType.pickup &&
      this.getters.settings.pickup?.discount
    ) {
      price = price * ((100 - this.getters.settings.pickup.discount) / 100);
    }

    return price;
  }

  get totalPriceDelivery() {
    let price = this.getters.totalProductsPrice;
    const paidByBonus = this.state.paidByBonus.reduce((sum, item) => {
      sum += item.price;
      return sum;
    }, 0);
    const priceWithoutPaidByBonus = price + paidByBonus;

    if (
      this.getters.settings.delivery?.deliveryPrice &&
      this.state.receive === ReceiveType.delivery
    ) {
      if (
        !this.getters.settings.delivery?.forFreeDelivery ||
        this.getters.settings.delivery?.forFreeDelivery > priceWithoutPaidByBonus
      ) {
        price += this.getters.settings.delivery.deliveryPrice;
      }
    }

    return price;
  }

  get categories(): ICategory[] {
    return this.getters.settings.list || [];
  }

  get settings(): IModuleSettings {
    const defaultSettings: IModuleSettings = {
      list: [],
      tab: true,
      icon: IconType.catalog,
      geocoderSuggestTemplates: {
        address: `[{{streetType}}.] [{{street}}], [{{houseType}}.] [{{house}}], [{{blockType}}.] [{{block}}]`,
        city: `[{{cityType}}.] [{{city}}] [{{settlementType}}.] [{{settlement}}], [{{area}}] [{{areaTypeFull}}]`,
        value: `[{{streetType}}.] [{{street}}], [{{houseType}}.] [{{house}}], [{{blockType}}.] [{{block}}]`,
      },
      geocoderAddressTemplates: {
        address: `[{{streetType}}.] [{{street}}], [{{houseType}}.] [{{house}}], [{{blockType}}.] [{{block}}]`,
        city: null,
      },
      maxPayBonusK: 100,
      minPaymentAfterBonus: 0,
      payByBonus: false,
      payByBonusType: 'product',
      amountControl: false,
    };

    const settings =
      this.app.state.appData?.settings?.modules?.catalog || defaultSettings;

    settings.tab = settings.tab ?? defaultSettings.tab;
    settings.icon = settings.icon ?? defaultSettings.icon;
    settings.geocoderSuggestTemplates =
      settings.geocoderSuggestTemplates ?? defaultSettings.geocoderSuggestTemplates;
    settings.geocoderAddressTemplates =
      settings.geocoderAddressTemplates ?? defaultSettings.geocoderAddressTemplates;
    settings.maxPayBonusK = settings.maxPayBonusK ?? defaultSettings.maxPayBonusK;
    settings.minPaymentAfterBonus =
      settings.minPaymentAfterBonus ?? defaultSettings.minPaymentAfterBonus;
    settings.payByBonus = settings.payByBonus ?? defaultSettings.payByBonus;
    settings.payByBonusType = settings.payByBonusType ?? defaultSettings.payByBonusType;
    settings.showOpeningHoursInfo = settings.showOpeningHoursInfo ?? true;

    return settings;
  }

  get minOrder(): number {
    if (!this.state.receive) {
      return 0;
    }

    const minOrder: Nullable<number> =
      this.state.receive === ReceiveType.delivery
        ? this.getters.settings.delivery?.minOrder
        : this.getters.settings.pickup?.minOrder;

    return minOrder || 0;
  }

  get deliveryDays(): Nullable<boolean[]> {
    let days = [false, false, false, false, false, false, false];

    if (
      (this.state.receive === ReceiveType.delivery &&
        !this.getters.settings.delivery?.days) ||
      (this.state.receive === ReceiveType.pickup && !this.state.pickupPoint?.days)
    ) {
      return undefined;
    } else {
      const workDays = (
        this.state.receive === ReceiveType.delivery
          ? this.getters.settings.delivery?.days
          : this.state.pickupPoint?.days
      )!;

      if (workDays) {
        if (workDays.length === 1 && !workDays[0]) {
          days = [true, true, true, true, true, true, true];
        } else {
          workDays.forEach((day: string) => {
            days[parseInt(day) - 1] = true;
          });
        }
      } else {
        return undefined;
      }
    }

    return days;
  }

  get deliveryTimeEnd(): Nullable<string[]> {
    const parts =
      this.state.receive === ReceiveType.delivery
        ? this.getters.settings.delivery?.end?.split(':')
        : this.state.pickupPoint?.endTime.split(':');

    if (parts && parts.length >= 2) {
      return timeZone(
        `${new Date().getFullYear()}-06-10T${parts[0].padStart(
          2,
          '0',
        )}:${parts[1].padStart(2, '0')}:00`,
        {
          formatString: 'HH:mm',
          timezone: this.app.getters.timeZoneOptions,
        },
      ).split(':');
    }

    return parts;
  }

  get deliveryTimeStart(): Nullable<string[]> {
    const parts =
      this.state.receive === ReceiveType.delivery
        ? this.getters.settings.delivery?.start?.split(':')
        : this.state.pickupPoint?.startTime.split(':');

    if (parts && parts.length >= 2) {
      return timeZone(
        `${new Date().getFullYear()}-06-10T${parts[0].padStart(
          2,
          '0',
        )}:${parts[1].padStart(2, '0')}:00`,
        {
          formatString: 'HH:mm',
          timezone: this.app.getters.timeZoneOptions,
        },
      ).split(':');
    }

    return parts;
  }

  notWorkingTime(branch?: Nullable<IBranch>): boolean {
    const schedule = checkWorkingTime({
      branchOrSchedule: branch,
      timezoneOptions: this.app.getters.timeZoneOptions,
      detailed: true,
    }) as WorkingSchedule;
    return !schedule.opened;
  }

  get deliveryDaysLabel(): Nullable<string> {
    const daysLabels: string[] = [];

    if (!this.getters.deliveryDays) {
      return undefined;
    } else {
      if (
        this.getters.deliveryDays[0] &&
        this.getters.deliveryDays[1] &&
        this.getters.deliveryDays[2] &&
        this.getters.deliveryDays[3] &&
        this.getters.deliveryDays[4] &&
        this.getters.deliveryDays[5] &&
        this.getters.deliveryDays[6]
      ) {
        return i18n.t('modules.catalog.workingTime.noWeekEnds').toString();
      } else if (
        this.getters.deliveryDays[0] &&
        this.getters.deliveryDays[1] &&
        this.getters.deliveryDays[2] &&
        this.getters.deliveryDays[3] &&
        this.getters.deliveryDays[4]
      ) {
        return i18n.t('modules.catalog.workingTime.weekdays').toString();
      } else {
        this.getters.deliveryDays.forEach((day, index) => {
          if (day) {
            switch (index) {
              case 0:
                daysLabels.push(
                  i18n.t('modules.catalog.workingTime.days.monday').toString(),
                );
                break;
              case 1:
                daysLabels.push(
                  i18n.t('modules.catalog.workingTime.days.tuesday').toString(),
                );
                break;
              case 2:
                daysLabels.push(
                  i18n.t('modules.catalog.workingTime.days.wednesday').toString(),
                );
                break;
              case 3:
                daysLabels.push(
                  i18n.t('modules.catalog.workingTime.days.thursday').toString(),
                );
                break;
              case 4:
                daysLabels.push(
                  i18n.t('modules.catalog.workingTime.days.friday').toString(),
                );
                break;
              case 5:
                daysLabels.push(
                  i18n.t('modules.catalog.workingTime.days.saturday').toString(),
                );
                break;
              case 6:
                daysLabels.push(
                  i18n.t('modules.catalog.workingTime.days.sunday').toString(),
                );
                break;
            }
          }
        });

        return daysLabels.join(', ');
      }
    }
  }

  get deliveryStartLabel(): Nullable<string> {
    if (
      this.getters.deliveryTimeStart &&
      this.getters.deliveryTimeStart[0] &&
      this.getters.deliveryTimeStart[1]
    ) {
      return `${this.getters.deliveryTimeStart[0]}:${this.getters.deliveryTimeStart[1]}`;
    }

    return undefined;
  }

  get deliveryEndLabel(): Nullable<string> {
    if (
      this.getters.deliveryTimeEnd &&
      this.getters.deliveryTimeEnd[0] &&
      this.getters.deliveryTimeEnd[1]
    ) {
      return `${this.getters.deliveryTimeEnd[0]}:${this.getters.deliveryTimeEnd[1]}`;
    }

    return undefined;
  }

  get workingTimeLabel(): string {
    let workingTime = '';

    if (this.getters.deliveryDaysLabel) {
      workingTime = this.getters.deliveryDaysLabel;
    }

    if (this.getters.deliveryStartLabel && this.getters.deliveryEndLabel) {
      if (this.getters.deliveryDaysLabel) {
        workingTime += ', ';
      }

      workingTime += `${this.getters.deliveryStartLabel} &ndash; ${this.getters.deliveryEndLabel}`;
    }

    return workingTime;
  }

  get notActiveProductsList(): IBasketEntry[] {
    return this.state.basket.filter(
      (item) =>
        (item.product.active != null && !item.product.active) || item.product.deleted,
    );
  }

  get notForSaleProductsList(): IBasketEntry[] {
    return this.state.basket.filter((item) => !this.getters.getAllowBasket(item.product));
  }

  get singleReceiveTypeAvailable(): boolean {
    const pickupActive = !!this.getters.settings.pickup;
    const deliveryActive = !!this.getters.settings.delivery;

    return !pickupActive || !deliveryActive;
  }

  get receiveBranchId(): Nullable<number | string> {
    if (this.state.receive === ReceiveType.pickup && this.state.pickupPoint) {
      return this.state.pickupPoint.idBranch;
    } else if (
      this.state.receive === ReceiveType.delivery &&
      this.state.deliveryAddress
    ) {
      return this.state.deliveryAddressBranch;
    }

    return null;
  }

  get receiveAvailable(): boolean {
    return (
      !!this.getters.settings.showBasket &&
      !!(this.getters.settings.pickup || this.getters.settings.delivery)
    );
  }

  get discount(): number {
    let discount = 0;

    if (
      this.state.receive === ReceiveType.pickup &&
      this.getters.settings.pickup?.discount
    ) {
      discount = this.getters.settings.pickup.discount;
    }

    return discount;
  }

  get bonusPayOrderMaxAmount(): number {
    let userBonus = this.app.getters.user?.bonus ?? 0;

    if (!userBonus) {
      return 0;
    }

    const maxPayBonusAfterBonus =
      this.getters.totalProductsPrice * ((100 - this.getters.discount) / 100) -
      this.getters.settings.minPaymentAfterBonus;
    if (maxPayBonusAfterBonus <= 0) {
      return 0;
    }
    userBonus = maxPayBonusAfterBonus <= userBonus ? maxPayBonusAfterBonus : userBonus;

    userBonus =
      this.getters.totalAmountOfBonusAvailableForPayByBonus <= userBonus
        ? this.getters.totalAmountOfBonusAvailableForPayByBonus
        : userBonus;

    return userBonus;
  }

  get deliveryNotWorkingTime(): boolean {
    const startTime = this.getters.settings.delivery?.start || '';
    const endTime = this.getters.settings.delivery?.end || '';

    if (!startTime || !endTime) return false;

    const pseudoBranch: Partial<IBranch> = {
      startTime,
      endTime,
      days: this.getters.settings.delivery?.days,
    };

    const workingTimeByDays: WorkingTime[] = [];
    pseudoBranch.days?.forEach((day) => {
      workingTimeByDays.push({
        startTime: pseudoBranch.startTime as string,
        endTime: pseudoBranch.endTime as string,
        day: Number(day),
      });
    });

    pseudoBranch.workingTime = workingTimeByDays;

    return this.getters.notWorkingTime(pseudoBranch as IBranch);
  }

  get productsInBasketWithPayBonus(): IBasketEntry[] {
    return this.state.basket.filter((entry) => entry.product.maxPayBonus > 0);
  }

  get checkZoneEnabled(): boolean {
    return (
      !!this.getters.settings.delivery?.checkZone &&
      this.state.receive === ReceiveType.delivery
    );
  }

  getAllowBasket(product: Nullable<IProduct>): boolean {
    if (product?.allowBasket == null) return true;
    if (product?.allowBasket === 'all') return true;
    if (product?.allowBasket === 'off') return false;

    return (
      (product?.allowBasket === 'onlyPickup' &&
        this.state.receive === ReceiveType.pickup) ||
      (product?.allowBasket === 'onlyDelivery' &&
        this.state.receive === ReceiveType.delivery)
    );
  }
}
