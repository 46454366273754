


import Vue from 'vue';

import { OSNotification } from 'onesignal-cordova-plugin';
import { f7 } from 'framework7-vue';

import bus from 'src/bus';
import * as API from '../api';

export default Vue.extend({
  data: () => ({
    id: null as Nullable<number>,
  }),
  destroyed() {
    bus.$off('product-push', this.handlePush);
  },
  mounted() {
    bus.$on('product-push', this.handlePush);
  },
  methods: {
    async handlePush(notification: OSNotification) {
      const additionalData = notification.additionalData as Nullable<OSAdditionalData>;
      this.id = additionalData?.idConnectedEntity as unknown as number;

      if (this.id) {
        f7.preloader.show();

        const products = await API.getProductsByIds({ products: [this.id] });

        if (products.length) {
          bus.$emit('product-popup', products[0]);
        }

        f7.preloader.hide();
      }
    },
  },
});
