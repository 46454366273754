




















































































































































import Vue, { PropType } from 'vue';
import { debounce } from 'lodash';
import { ContentLoader } from 'vue-content-loader';

import { ICategory, IProduct, ReceiveType } from '../types';

import bus from '@/bus';
import { appStoreMapper } from '@/store/appstore';
import { catalogStoreMapper } from '../store';
import * as API from '../api';
import { showErrorDialog } from '@/misc';
import getImageThumbnailPath from '@/utils/getImageThumbnailPath';
import config from '@/app.config.js';

import CatalogItem from '@/components/CatalogItem.vue';
import CatalogBarWrapper from './CatalogBarWrapper.vue';

export default Vue.extend({
  name: 'CatalogSearch',
  components: { ContentLoader, CatalogItem, CatalogBarWrapper },
  props: {
    categoryId: Number as PropType<number>,
    searchQuery: String as PropType<string>,
    products: Array as PropType<IProduct[]>,
  },
  data: () => ({
    config,
    query: '',
    productsList: [] as IProduct[],
    categoriesList: [] as ICategory[],
    loading: false,
    loadedSuccessfully: false,
    pageContent: null as any,
    scrollTop: 0,
    loadedImages: [] as string[],
    blockQueryWatch: false,
  }),
  beforeDestroy() {
    this.pageContent?.removeEventListener('scroll', this.scrollHandler);
  },
  created() {
    this.query = this.searchQuery || '';
    this.productsList = this.products || [];

    this.$watch(
      () => this.query,
      () => {
        if (this.blockQueryWatch) return false;

        this.search();

        if (this.query.length < 3) {
          this.categoriesList = [];
          this.productsList = [];
          this.loadedSuccessfully = true;
          this.loading = false;
        } else {
          this.loading = true;
        }
      },
    );
  },
  mounted() {
    this.pageContent = (this.$refs.page as unknown as Vue).$el.querySelector(
      '.page-content',
    );
    this.pageContent?.addEventListener('scroll', this.scrollHandler);
  },
  computed: {
    nothingIsFound(): boolean {
      return (
        !this.loading &&
        this.query.length >= 3 &&
        this.loadedSuccessfully &&
        !this.productsList.length &&
        !this.categoriesList.length
      );
    },
    showBar(): boolean {
      return !!this.settings.showBasket && !!this.totalProducts;
    },
    currentSearchIndex(): number {
      let count = 0;
      this.$f7.views.current.router.history.forEach((route) => {
        if (route === '/catalog/search') count++;
      });
      return count - 1;
    },
    ...appStoreMapper.mapGetters(['decimalOptions', 'fullImagePath', 'deviceWidth']),
    ...catalogStoreMapper.mapGetters(['settings', 'totalProducts', 'receiveBranchId']),
    ...catalogStoreMapper.mapState([
      'basket',
      'pickupPoint',
      'receive',
      'deliveryAddress',
      'searchCache',
      'categories',
    ]),
  },
  methods: {
    findCategoryById({
      categoryId,
      subCategories,
    }: {
      categoryId: number;
      subCategories?: ICategory[];
    }): Nullable<ICategory> {
      const subs = subCategories || this.categories;
      let foundCategory: Nullable<ICategory>;

      subs.forEach((category) => {
        if (!foundCategory) {
          if (category.id === categoryId) {
            foundCategory = category;
          }

          if (!foundCategory && category.sub_categories.length) {
            foundCategory = this.findCategoryById({
              categoryId,
              subCategories: category.sub_categories,
            });
          }
        }
      });

      return foundCategory;
    },
    onPageBeforeOut() {
      if (this.searchCache.length - 1 === this.currentSearchIndex + 1) {
        this.clearSearchCache(this.searchCache.length - 1);
      }
    },
    onPageBeforeIn() {
      this.blockQueryWatch = true;

      if (this.searchCache[this.currentSearchIndex]) {
        this.query = this.searchCache[this.currentSearchIndex].query;
        this.categoriesList = this.searchCache[this.currentSearchIndex].cache.categories;
        this.productsList = this.searchCache[this.currentSearchIndex].cache.products;
      }

      this.$nextTick(() => {
        this.blockQueryWatch = false;
      });
    },
    onPageAfterIn() {
      if (!this.products?.length) {
        (this.$refs.input as unknown as Vue).$el.querySelector('input')?.focus();
      }
    },
    imageOnLoad(id: string) {
      this.loadedImages.push(id);
    },
    imageLoadedSuccessfully(id: string): boolean {
      return !!this.loadedImages.find((i) => i === id);
    },
    scrollHandler() {
      this.scrollTop = this.pageContent?.scrollTop;
    },
    onProductUpdateAmount({
      product,
      $event: amount,
    }: {
      product: IProduct;
      $event: number;
    }) {
      if (this.getProductAmountInBasket(product) !== amount) {
        this.addToBasket({
          amount: amount - this.getProductAmountInBasket(product),
          product,
        });
      }
    },
    goToProduct(product: IProduct) {
      bus.$emit('product-popup', product);
    },
    goToCategory(category: ICategory) {
      if (
        (this.receive === ReceiveType.pickup && !this.pickupPoint) ||
        (this.receive === ReceiveType.delivery && !this.deliveryAddress)
      ) {
        bus.$emit('delivery:block');
      } else {
        this.$f7?.views.current.router.navigate(`/catalog/category/${category.id}`);
      }
    },
    getProductMaxAmount(product: IProduct): Nullable<number> {
      return this.settings.amountControl
        ? product.amounts?.find((entry) => entry.branch_id === this.receiveBranchId)
            ?.amount ?? 0
        : undefined;
    },
    getProductAmountInBasket(product: IProduct): number {
      return this.basket.find((entry) => entry.product.id === product.id)?.amount || 0;
    },
    getProductImage(product: IProduct): string {
      if (!product.images[0]) return '';

      return this.fullImagePath(
        getImageThumbnailPath(product.images[0], { deviceWidth: this.deviceWidth }),
      );
    },
    getCategoryImage(photoId: Nullable<number>): string {
      if (!photoId) return '';

      return this.fullImagePath(
        getImageThumbnailPath(photoId, { deviceWidth: this.deviceWidth }),
      );
    },
    getSearchResult() {
      if (this.query.length >= 3) {
        this.loadedImages = [];
        this.loading = true;

        API.catalogSearch({ query: this.query, category: this.categoryId })
          .then((response) => {
            this.productsList = response.products;
            this.categoriesList = response.categories;
            this.updateSearchCache({
              cache: response,
              scrollTop: 0,
              query: this.query,
              level: this.currentSearchIndex,
            });
            this.loadedSuccessfully = true;
          })
          .catch((error) => {
            this.loadedSuccessfully = false;
            showErrorDialog({ error });
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },
    search: debounce(function (this: any) {
      this.getSearchResult();
    }, 300),
    getShowAmountControl(product: IProduct): boolean {
      return !!this.settings.showBasket && !product.isVariation;
    },
    getProductAllowBasket(product: IProduct): boolean {
      if (product.allowBasket == null) return true;
      if (product.allowBasket === 'all') return true;
      if (product.allowBasket === 'off') return false;

      return (
        (product.allowBasket === 'onlyPickup' && this.receive === ReceiveType.pickup) ||
        (product.allowBasket === 'onlyDelivery' && this.receive === ReceiveType.delivery)
      );
    },
    ...catalogStoreMapper.mapActions([
      'addToBasket',
      'getProductsByCategoryId',
      'getCategories',
    ]),
    ...catalogStoreMapper.mapMutations(['updateSearchCache', 'clearSearchCache']),
  },
  watch: {
    productsList() {
      if (this.productsList.length) {
        setTimeout(() => {
          this.$f7?.lazy.create(this.$el as HTMLElement);
        }, 100);
      }
    },
  },
});
