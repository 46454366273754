










































































import { computed, defineComponent, PropType, ref, watch } from 'vue';

import { getLocalizedPrice } from '@/utils';
import { useAppStore } from '@/store/appstore';
import { useObjections } from '@/composition';

interface IOptions {
  decimalRoundingRule: decimalRoundingRule;
  decimal?: number;
}

export default defineComponent({
  name: 'CatalogProductBar',
  props: {
    id: {
      type: Number as PropType<number>,
      required: true,
    },
    amount: {
      type: Number as PropType<number>,
      required: true,
      validator: (value: number) => !(value % 1) && value >= 0,
    },
    price: {
      type: Number as PropType<number>,
      required: true,
      validator: (value: number) => value >= 0,
    },
    maxAmount: {
      type: Number as PropType<Nullable<number>>,
      validator: (value: number) => value >= 0,
    },
    disabled: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    options: {
      type: Object as PropType<IOptions>,
      default: () => ({
        decimalRoundingRule: 'floor',
      }),
      validator: (options: IOptions) => {
        return (
          options.decimalRoundingRule === 'floor' ||
          options.decimalRoundingRule === 'ceil' ||
          options.decimalRoundingRule === 'default' ||
          (!!options.decimalRoundingRule && options.decimal != null)
        );
      },
    },
  },
  setup(props, { emit }) {
    const appStore = useAppStore();

    const { checkObjection } = useObjections();

    const amountInitial = ref<number>(props.amount);
    const amountInner = ref<number>(props.amount);

    const priceFormatted = computed<string>(() => {
      return getLocalizedPrice(
        props.price,
        appStore.getters.currency,
        props.options.decimalRoundingRule,
        props.options.decimal,
      );
    });
    const maxAmountReached = computed<boolean>(() =>
      !!props.maxAmount ? amountInner.value >= props.maxAmount : false,
    );
    const isEmpty = computed<boolean>(() => amountInner.value === 0);
    const doShake = ref<boolean>(false);
    const plusPause = ref<boolean>(false);
    const hideControls = ref<boolean>(false);
    const actionButtonDisabled = computed<boolean>(
      () =>
        amountInitial.value === amountInner.value ||
        (!amountInner.value && !amountInitial.value),
    );
    const actionButtonToDelete = computed<boolean>(
      () => !amountInner.value && amountInner.value < amountInitial.value,
    );
    const actionButtonToAdd = computed<boolean>(() => !amountInitial.value);

    watch(
      () => props.amount,
      () => {
        amountInner.value = props.amount;
        amountInitial.value = props.amount;
      },
    );

    watch(
      () => props.id,
      () => {
        amountInner.value = props.amount;
        amountInitial.value = props.amount;
      },
    );

    function onMinusClick() {
      if (amountInner.value === 1) {
        hideControls.value = true;
        plusPause.value = true;
        setTimeout(() => {
          hideControls.value = false;
          amountInner.value -= 1;
          setTimeout(() => {
            plusPause.value = false;
          }, 200);
        }, 200);
      } else {
        if (amountInner.value <= 0) return;
        amountInner.value -= 1;
      }
    }

    function onPlusClick() {
      checkObjection('catalog:basket:add').then((result) => {
        if (!result) {
          if (!!props.maxAmount && amountInner.value >= props.maxAmount) {
            doShake.value = true;
            setTimeout(() => {
              doShake.value = false;
            }, 500);

            return;
          }

          if (amountInner.value === 0) {
            hideControls.value = true;
            plusPause.value = true;
            setTimeout(() => {
              hideControls.value = false;
              plusPause.value = false;
              amountInner.value += 1;
            }, 200);
          } else {
            amountInner.value += 1;
          }
        }
      });
    }

    function onActionClick() {
      amountInitial.value = amountInner.value;
      emit('update:amount', amountInner.value);
    }

    return {
      amountInitial,
      amountInner,
      priceFormatted,
      isEmpty,
      maxAmountReached,
      doShake,
      plusPause,
      hideControls,
      actionButtonDisabled,
      actionButtonToDelete,
      actionButtonToAdd,
      onMinusClick,
      onPlusClick,
      onActionClick,
    };
  },
});
